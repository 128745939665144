<template>
  <div class="col-12">
    <!-- <div class="card"> -->
    <Toast />
    <DataTable
      ref="dt"
      dataKey="id"
      :class="meta.tableClass"
      :value="listEntities"
      v-model:selection="selectedEntities"
      v-model:expandedRows="expandedRows"
      :showGridlines="true"
      :filterDisplay="meta.filter?.filterDisplay"
      v-model:filters="filters"
      :globalFilterFields="meta.filter?.globalFilterFields"
      :editMode="meta.editMode"
      :loading="meta.loading"
      :lazy="meta.lazy"
      :totalRecords="meta.paginator.totalRecords"
      :paginator="meta.paginator.enable"
      :rows="meta.paginator.rows"
      :paginatorTemplate="
        meta.paginator.template ||
        'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
      "
      :rowsPerPageOptions="meta.paginator.rowsPerPageOptions"
      :currentPageReportTemplate="meta.paginator.currentPageReportTemplate"
      :sortMode="meta.sortMode"
      :multiSortMeta="meta.multiSortMeta"
      v-model:editingRows="editingRows"
      responsiveLayout="scroll"
      @rowExpand="onRowExpand"
      @rowCollapse="onRowCollapse"
      @row-edit-save="onRowEditSave"
      @cell-edit-complete="onCellEditComplete"
      @page="onPage"
      @filter="onFilter"
      @sort="onSort"
    >
      <template #loading>{{ $t("common.loading_message") }}</template>
      <template
        #header
        v-if="
          (meta.tableActions && meta.tableActions.length > 0) || meta.searchable
        "
      >
        <div
          class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
        >
          <div class="my-2">
            <Button
              v-for="(action, index) in meta.tableActions"
              :key="index"
              :icon="action.icon"
              :class="action.style"
              :label="$t('button.' + action.name)"
              @click="() => this.onTableAction(action)"
            />
          </div>
          <div>
            <h3>{{ meta.header?.title }}</h3>
          </div>
          <div class="block mt-2 md:mt-0">
            <IconField iconPosition="left" v-if="meta.searchable">
                <InputIcon>
                    <i class="pi pi-search" />
                </InputIcon>
                <InputText v-model="filters['global'].value" 
                  :placeholder="$t('placeholder.search')"
                  @change="onGlobalFilter" />
            </IconField>
            <!-- <span class="p-input-icon-left" v-if="meta.searchable">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('placeholder.search')"
                @change="onGlobalFilter"
              />
            </span> -->
          </div>
        </div>
      </template>
      <Column
        :expander="meta.expander"
        headerStyle="width: 3rem"
        v-if="meta.expander"
      />
      <Column
        :selectionMode="meta.selectionMode"
        headerStyle="width: 3rem"
        v-if="
          meta.selectionMode == 'single' || meta.selectionMode == 'multiple'
        "
      ></Column>
      <Column
        v-if="meta.itemActions && meta.itemActions.length > 0"
        :headerStyle="meta.actionsHeaderStyle || 'width:4%; min-width:8rem;'"
        bodyStyle="text-align:center"
      >
        <template #body="slotProps">
          <Button
            v-for="(action, index) in meta.itemActions.filter((action, index) =>
              this._showItemAction(index, slotProps.data)
            )"
            :key="index"
            :icon="action.icon"
            :class="action.style"
            @click="
              this.$emit(
                'itemAction',
                action.name,
                slotProps.data,
                slotProps.index
              )
            "
          />
        </template>
      </Column>
      <Column
        v-for="column in visibleColumns"
        :key="column.name"
        :dataType="column.dataType"
        :field="column.name"
        :header="column.label || $t(this.meta.entityName + '.' + column.name)"
        :sortable="true"
        :headerStyle="column.headerStyle"
      >
        <template
          #editor="{ data, field }"
          v-if="
            (meta.editMode == 'row' || meta.editMode == 'cell') &&
            column.editor &&
            column.editor.enable
          "
        >
          <Calendar
            v-if="column.dataType == 'date'"
            v-model="data[field]"
            :autofocus="meta.editMode == 'cell'"
            :dateFormat="meta.dateFormat"
            :style="column.editor.style"
          />
          <InputNumber
            v-else-if="column.dataType == 'numeric'"
            v-model="data[field]"
            :style="column.editor.style"
          />
          <InputText
            v-else
            v-model="data[field]"
            :autofocus="meta.editMode == 'cell'"
            :style="column.editor.style"
          />
        </template>
        <template #body="slotProps" v-else>
          <span class="p-column-title">{{
            column.label || $t(this.meta.entityName + "." + column.name)
          }}</span>
          <component
            v-if="column.component"
            :is="column.component"
            v-bind="{ column, data: slotProps.data }"
          />
          <div v-else-if="column.link">
            <Button
              :label="slotProps.data[column.name]"
              link
              class="p-button-text underline"
              @click="column.link(slotProps.data, column)"
            />
          </div>
          <div :style="column.bodyStyle" v-else>
            {{
              column.formatter
                ? column.formatter(slotProps.data[column.name])
                : slotProps.data[column.name]
            }}
          </div>
        </template>
        <template #filter="{ filterModel }" v-if="column.filter">
          <MultiSelect v-if="column.filterOptions"
              v-model="filterModel.value"
              :options="column.filterOptions"
              optionValue="value"
              optionLabel="label"
              placeholder="Any"
              class="p-column-filter"
            >
              <template #option="slotProps">
                {{ slotProps.option.label }}
              </template>
          </MultiSelect>
          <Calendar
            v-else-if="column.dataType == 'date'"
            v-model="filterModel.value"
            dateFormat="mm/dd/yy"
            placeholder="mm/dd/yyyy"
          />
          <InputNumber
            v-else-if="column.dataType == 'numeric'"
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            :placeholder="
              $t('common.search_by_field', [
                column.label || $t(this.meta.entityName + '.' + column.name),
              ])
            "
          />
          <InputText
            v-else
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            :placeholder="
              $t('common.search_by_field', [
                column.label || $t(this.meta.entityName + '.' + column.name),
              ])
            "
          />
        </template>
      </Column>
      <Column
        :rowEditor="meta.rowEditor"
        style="width: 10%; min-width: 8rem"
        bodyStyle="text-align:center"
        v-if="meta.rowEditor"
      ></Column>
      <template #expansion="{ data }" v-if="meta.expander">
        <div v-if="meta.subComponent">
          <component
            :is="meta.subComponent"
            v-bind="{ entities: data[meta.metaSubTable.dataField] }"
          />
        </div>
        <div class="subtable" v-else-if="meta.metaSubTable">
          <crud-table
            :entities="data[meta.metaSubTable.dataField]"
            :meta="meta.metaSubTable"
            @tableAction="onTableAction"
            @itemAction="onItemAction"
          />
        </div>
      </template>
    </DataTable>
    <!-- </div> -->
  </div>
</template>
<script>
import { compile } from "expression-eval";
//import {FilterMatchMode} from 'primevue/api';
import AuthService from "@/service/AuthService";
import filter from "@/mixins/filter";
//import CrudToolbar from './CrudToolbar';
export default {
  name: "crud-table",
  mixins: [filter],
  emits: [
    "cell-edit-complete",
    "itemAction",
    "filter",
    "page",
    "row-edit-complete",
    "sort",
    "tableAction",
    "update:selection",
  ],
  components: {},
  props: {
    entities: {
      type: Array,
      default() {
        return [];
      },
    },
    selection: {
      type: Array,
      default() {
        return [];
      },
    },
    meta: {
      type: Object,
      default() {
        return {
          tableActions: [],
          itemActions: [],
        };
      },
    },
  },
  data() {
    return {
      listEntities: this.entities,
      selectedEntities: [],
      submitted: false,
      edittingEntity: {},
      editingRows: [],
      expandedRows: null,
      filters: {},
    };
  },
  computed: {
    // a computed getter
    visibleColumns() {
      const user = AuthService.currentUser;
      var columns = this.meta.columns.filter((column) => {
        if (column.condition) {
          const fn = compile(column.condition);
          return fn({ user });
        }
        return true;
      });
      return columns;
    },
  },
  created() {
    this.initGlobalFilter(this.filters);
    if (Array.isArray(this.meta.itemActions)) {
      this.fnItemActionConditions = this.meta.itemActions.map((action) => {
        if (action.condition) {
          return compile(action.condition);
        } else {
          return function () {
            return true;
          };
        }
      });
    }
    this.fnColumnConditions = this.meta.columns.map((column) => {
      if (column.condition) {
        return compile(column.condition);
      } else {
        return function () {
          return true;
        };
      }
    });
  },
  mounted() {
    this.initColumnFilters(this.$refs.dt, this.filters);
    // this.$watch(() => this.$props.meta,(meta) => {
    // 		console.log(meta);
    // });
    this.$watch("selectedEntities", (selection) => {
      this.$emit("update:selection", selection);
    });
  },
  updated() {
    this.listEntities = this.entities;
  },
  methods: {
    onRowCollapse() {
      console.log("rowCollapse");
    },
    onRowExpand(event) {
      this.$emit("rowExpand", event);
    },
    onCellEditComplete(event) {
      this.$emit("cell-edit-complete", event);
    },
    onRowEditSave() {
      console.log("rowEditSave");
    },
    onPage(pageEvent) {
      this.$emit("paging", pageEvent);
    },
    onFilter(event) {
      this.$emit("filter", event);
    },
    onGlobalFilter() {
      if (this.meta.filter?.globalFilterFields) {
        let filters = { ...this.filters };
        let value = this.filters.global.value;
        filters["_global_"] = {
          operator: "or",
          constraints: this.meta.filter?.globalFilterFields.map((field) => ({
            matchMode: field,
            value,
          })),
        };
        this.$emit("filter", { filters });
      }
    },
    onSort(event) {
      this.$emit("sort", event);
    },
    onTableAction(action) {
      if (action.name == "filterclear") {
        this.clearFilters();
        this.$emit("filter", event);
      } else {
        this.$emit("tableAction", action.name);
      }
    },
    onItemAction(action, item) {
      this.$emit("subtable-item-action", action, item);
    },
    _showItemAction(index, data) {
      const fn = this.fnItemActionConditions[index];
      const res = fn(data);
      return res;
    },
    _showColumn(index) {
      const fn = this.fnColumnConditions[index];
      const res = fn({
        user: AuthService.currentUser,
      });
      return res;
    },
  },
};
</script>
